import React from "react";
import "./Hero.css";
import hero_image from "../Assets/hero_image.png";
import hand_icon from "../Assets/hand_icon.png";
import arrow_icon from "../Assets/arrow.png";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-left">
 
        <p>배너</p>
        
      </div>
      <div className="hero-right">
       
      </div>
    </div>
  );
};

export default Hero;
